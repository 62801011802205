import { apiInstance } from './utils';

export default {
  getBexioLoginRedirectUrl: () =>
    apiInstance.request({
      method: 'GET',
      url: '/bexio/login/',
    }),
  createAccount: ({
    data,
  }: {
    data: {
      code?: string;
      terms_agreement: boolean;
      privacy_policy_accepted: boolean;
    };
  }) =>
    apiInstance.request({
      method: 'POST',
      url: '/bexio/success/',
      data: data,
    }),
};
