import { apiInstance } from './utils';

export default {
  createProject: (projectData: any) =>
    apiInstance.request({
      method: 'POST',
      url: `/projects/create/`,
      data: projectData,
    }),
  createProjectWithAuth: (projectData: any, organization: string) =>
    apiInstance.request({
      method: 'POST',
      url: `/org/${organization}/projects/`,
      data: projectData,
    }),
};
