import axios from 'axios';
import Cookies from 'js-cookie';

const ENV =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

const AUTH = {
  TOKEN: '__deeptrueAuth',
  USER: '__deeptrueUser',
};

let API_URL = '';

switch (ENV) {
  case 'local':
    API_URL = 'http://localhost:8000/v1';
    break;
  case 'development':
    API_URL = 'https://api-dev.deeptrue.com/v1';
    break;
  default:
    API_URL = 'https://api.deeptrue.com/v1';
    break;
}

export const apiInstance = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: `Bearer ${Cookies.get(AUTH.USER)}`,
  },
});

apiInstance.interceptors.request.use(
  function (config) {
    const authorizedLoggedUserToken = Cookies.get(AUTH.TOKEN);

    if (authorizedLoggedUserToken) {
      config.headers['Authorization'] = `JWT ${authorizedLoggedUserToken}`;
    } else {
      config.headers['Authorization'] = `Bearer ${Cookies.get(AUTH.USER)}`;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    // eslint-disable-next-line
    return Promise.reject(error);
  }
);
