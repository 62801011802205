import { apiInstance } from './utils';

export default {
  getOrganizationName: (organization: string) =>
    apiInstance.request({
      method: 'POST',
      url: `/assistant/organization-name/`,
      data: {
        name: organization,
      },
    }),
};
