import React, { useEffect, useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import api from '../api';

export const currencySymbols = {
  EUR: '€',
  CHF: 'CHF',
  USD: '$',
  AUD: 'A$',
  GBP: '£',
  CAD: 'C$',
};
const defaultValue = {
  pricingData: {
    currencyOptions: [],
    currencyRates: {},
    currentCurrency: 'EUR',
  },
  toolsPricing: null,
  setPricingData: (_args: any) => null,
  calculateCreditsByRate: (credits: number) => credits,
};
export const getCurrencyName = (name: string) => {
  return name.slice(0, 3);
};
export const PricingContext = React.createContext(defaultValue);

const createPricingData = (data) => {
  const pricingData = {};

  for (const tool of data) {
    pricingData[tool.pricing_product_slug] = tool;
  }

  return pricingData;
};

export const getLowestToolPriceInWorkbench = (toolsPrices, workbench) => {
  const lowestToolPriceInWorkbench = {};

  for (const tool in toolsPrices) {
    const currentWorkbench = toolsPrices[tool].pricing_workbench_name;

    if (
      lowestToolPriceInWorkbench[currentWorkbench] ||
      lowestToolPriceInWorkbench[currentWorkbench] === 0
    ) {
      if (
        lowestToolPriceInWorkbench[currentWorkbench] > toolsPrices[tool].price
      ) {
        lowestToolPriceInWorkbench[currentWorkbench] = toolsPrices[tool].price;
      }
      if (toolsPrices[tool].is_free) {
        lowestToolPriceInWorkbench[currentWorkbench] = 0;
      }
    } else {
      if (toolsPrices[tool].is_free) {
        lowestToolPriceInWorkbench[currentWorkbench] = 0;
      } else {
        lowestToolPriceInWorkbench[currentWorkbench] = toolsPrices[tool].price;
      }
    }
  }
  return lowestToolPriceInWorkbench[workbench];
};

export const getLowestToolPrice = (toolPrices) => {
  let lowestToolPrice = 99999999;

  for (const tool in toolPrices) {
    if (toolPrices[tool].price < lowestToolPrice) {
      lowestToolPrice = toolPrices[tool].price;
    }
  }
  return lowestToolPrice;
};

const PricingProvider = ({ children }) => {
  const defaultCurrencyOptions = { text: 'EUR (€)', value: 'EUR' };
  const lastUsedCurrencyOptions =
    JSON.parse(localStorage.getItem('currentCurrencyOption')) ??
    defaultCurrencyOptions;

  const [pricingData, setPricingData] = useState({
    currencyOptions: [defaultCurrencyOptions],
    currencyRates: { EUR: 1.0 },
    currentCurrency: lastUsedCurrencyOptions.value,
  });

  useEffect(() => {
    api.pricing.getPricing().then((data) => {
      const updatedData = {
        currencyOptions: [...pricingData.currencyOptions],
        currencyRates: { ...pricingData.currencyRates },
        currentCurrency: pricingData.currentCurrency,
      };

      for (const currency of data.data) {
        updatedData.currencyOptions.push({
          text:
            currency.convert_to === 'CHF'
              ? currency.convert_to
              : `${currency.convert_to} (${
                  currencySymbols[currency.convert_to]
                })`,
          value: currency.convert_to,
        });
        updatedData.currencyRates[currency.convert_to] = currency.currency_rate;
      }
      setPricingData(updatedData);
    });
  }, []);

  const calculateCreditsByRate = (credits: number) => {
    const { currentCurrency, currencyRates } = pricingData;
    const rate = Number(currencyRates[currentCurrency] || 1);

    return credits * rate;
  };

  return (
    <StaticQuery
      query={graphql`
        query ToolPricingQuery($lang: String) {
          allPrismicCommonInformation(filter: { lang: { eq: $lang } }) {
            edges {
              node {
                lang
                data {
                  tool {
                    pricing_workbench_name
                    pricing_product_slug
                    is_free
                    price
                    pricing_product_name
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const toolsPricing = createPricingData(
          data.allPrismicCommonInformation.edges[0].node.data.tool
        );
        return (
          <PricingContext.Provider
            value={{
              pricingData: pricingData,
              setPricingData: setPricingData,
              toolsPricing,
              calculateCreditsByRate: calculateCreditsByRate,
            }}
          >
            {children}
          </PricingContext.Provider>
        );
      }}
    />
  );
};

export default PricingProvider;
