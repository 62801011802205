/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import AuthProvider from './src/providers/AuthProvider';
import PricingProvider from './src/providers/PricingProvider';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import React from 'react';

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: 'deeptrue',
      },
      {
        repositoryName: 'deeptrue-blog',
      },
    ]}
  >
    <PricingProvider>
      <AuthProvider>{element}</AuthProvider>
    </PricingProvider>
  </PrismicPreviewProvider>
);
