import productsApi from './products';
import authApi from './auth';
import projectApi from './project';
import bexioApi from './bexio';
import pricing from './pricing';
import organization from './organization';

export default {
  productsApi,
  authApi,
  projectApi,
  bexioApi,
  pricing,
  organization,
};
