exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-bexio-tsx": () => import("./../../../src/pages/bexio.tsx" /* webpackChunkName: "component---src-pages-bexio-tsx" */),
  "component---src-pages-blog-article-tsx": () => import("./../../../src/pages/blog/article.tsx" /* webpackChunkName: "component---src-pages-blog-article-tsx" */),
  "component---src-pages-brief-tsx": () => import("./../../../src/pages/brief.tsx" /* webpackChunkName: "component---src-pages-brief-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-order-processing-tsx": () => import("./../../../src/pages/order-processing.tsx" /* webpackChunkName: "component---src-pages-order-processing-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/BlogTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-markdown-template-tsx": () => import("./../../../src/templates/MarkdownTemplate.tsx" /* webpackChunkName: "component---src-templates-markdown-template-tsx" */),
  "component---src-templates-products-template-tsx": () => import("./../../../src/templates/ProductsTemplate.tsx" /* webpackChunkName: "component---src-templates-products-template-tsx" */),
  "component---src-templates-workbench-template-tsx": () => import("./../../../src/templates/WorkbenchTemplate.tsx" /* webpackChunkName: "component---src-templates-workbench-template-tsx" */)
}

