import { apiInstance } from './utils';

export default {
  getPublicToken: () =>
    apiInstance.request({
      method: 'GET',
      url: `/assistant/public-token/`,
    }),
  refreshToken: (token: string) =>
    apiInstance.request({
      method: 'POST',
      url: '/auth/refresh/',
      data: {
        token,
      },
    }),
};
