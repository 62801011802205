import React, { useEffect, useState, useContext } from 'react';
import Cookies from 'js-cookie';
import api from '../api';

export const AUTH = {
  TOKEN: '__deeptrueAuth',
  USER: '__deeptrueUser',
};

const userLocalStorage = {
  getToken: () => JSON.parse(localStorage.getItem(AUTH.TOKEN)) || {},
  setToken: (token) => localStorage.setItem(AUTH.TOKEN, JSON.stringify(token)),
  clearToken: () => localStorage.removeItem(AUTH.TOKEN),
  getUser: () =>
    JSON.parse(localStorage.getItem(AUTH.USER)) || {
      token: undefined,
      user: {},
    },
  setUser: (user) => localStorage.setItem(AUTH.USER, JSON.stringify(user)),
  clearUser: () => localStorage.removeItem(AUTH.USER),
};

export const AuthContext = React.createContext({
  authData: {
    token: null,
    user: {},
  },
});

export const useAuth = () => useContext(AuthContext);

export interface AuthDataTypes {
  token: string | undefined;
  user: {
    created_at?: string;
    email?: string;
    first_name?: string;
    language?: string;
    last_name?: string;
    organizations?: any;
    phone?: string;
    role?: number;
    terms_agreement?: boolean;
    updated_at?: string;
    username?: string;
    uuid?: string;
  };
  isLoading: boolean;
}

const AuthProvider = ({ children }: any) => {
  const [authData, setAuthData] = useState<AuthDataTypes>({
    token: undefined,
    user: {},
    isLoading: true,
  });

  const isProduction = process.env.NODE_ENV === 'production';

  const checkToken = async () => {
    const token = Cookies.get(AUTH.TOKEN);

    if (token) {
      userLocalStorage.setToken(token);
      await api.authApi
        .refreshToken(token)
        .then(({ data }) => {
          userLocalStorage.setUser(data);
          setAuthData({ ...data, isLoading: false });
        })
        .catch(() => {
          console.log('catch');
          userLocalStorage.clearUser();
          userLocalStorage.clearToken();

          Cookies.remove(AUTH.TOKEN, {
            path: '/',
            domain: isProduction ? '.deeptrue.com' : 'localhost',
          });
          //  PROBLEM WITH FETCHING CURRENCIES MIGGHT BE FIXED BY THIS - REQUIRE FURTHER INVESTIGATION
          api.authApi.getPublicToken().then(({ data }) => {
            Cookies.set(AUTH.USER, data.token);
            userLocalStorage.clearToken();
            setAuthData({
              token: data.token,
              user: {},
              isLoading: false,
            });
          });
        });
    } else {
      await api.authApi.getPublicToken().then(({ data }) => {
        Cookies.set(AUTH.USER, data.token);
        userLocalStorage.clearToken();
        setAuthData({
          token: data.token,
          user: {},
          isLoading: false,
        });
      });
    }
  };

  useEffect(() => {
    if (!authData.token) {
      checkToken();
    }
  }, [authData.token]);

  return (
    <AuthContext.Provider
      value={{
        authData,
      }}
    >
      {!authData.isLoading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
